import { useMenuSlice } from "~/store/menuSlice";

export default defineNuxtRouteMiddleware((to) => {
  if (!process.server) {
    const menuStore = useMenuSlice();
    if (to.path === "/menu" && Object.keys(to.query).length === 0) {
      if (!to.query.product) {
        menuStore.clearParams();
      }
    }
  }
});
